var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-card",
    { attrs: { color: "transparent", tile: "", elevation: "0" } },
    [
      _c(
        "v-card",
        {
          staticClass: "py-sm-8 py-4",
          attrs: { color: "light_gray", tile: "", elevation: "0" },
        },
        [
          _c(
            "v-row",
            { attrs: { justify: "center", "no-gutters": "" } },
            [
              _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
                _c(
                  "span",
                  { staticClass: "text-sm-h5 text-body-1 font-weight-bold" },
                  [_vm._v(_vm._s(_vm.$t("label.myAccount")))]
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "my-8", attrs: { justify: "center", "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "11", lg: "9" } }, [
            _c(
              "div",
              { staticClass: "d-block d-md-flex mb-6" },
              [
                _c(
                  "v-col",
                  { attrs: { cols: "12", md: "3" } },
                  [
                    _c(
                      "v-card",
                      {
                        staticClass: "pa-6 rounded-lg mb-6",
                        attrs: {
                          color: "transparent",
                          tile: "",
                          elevation: "3",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "font-weight-bold text-body-1 mb-2" },
                          [_vm._v(_vm._s(_vm.$t("label.accountSetting")))]
                        ),
                        _c("div", { staticClass: "pl-4" }, [
                          _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize pa-0 mx-0 v-align-initial",
                                  attrs: {
                                    to: { name: _vm.routeName.ACCOUNT },
                                    "exact-active-class":
                                      "font-weight-bold unbox_primary--text",
                                    height: "auto",
                                    text: "",
                                    plain: "",
                                    ripple: false,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-body-2 px-0" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.accountInfo"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize pa-0 mx-0 v-align-initial",
                                  attrs: {
                                    to: { name: _vm.routeName.CHANGE_PASSWORD },
                                    "exact-active-class":
                                      "font-weight-bold unbox_primary--text",
                                    height: "auto",
                                    text: "",
                                    plain: "",
                                    ripple: false,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-body-2 px-0" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.changePassword"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize pa-0 mx-0 v-align-initial",
                                  attrs: {
                                    to: {
                                      name: _vm.routeName.DELIVERY_ADDRESSES,
                                    },
                                    "exact-active-class":
                                      "font-weight-bold unbox_primary--text",
                                    height: "auto",
                                    text: "",
                                    plain: "",
                                    ripple: false,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-body-2 px-0" },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("label.deliveryInformation")
                                        )
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "py-2" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "text-capitalize pa-0 mx-0 v-align-initial",
                                  attrs: {
                                    to: { name: _vm.routeName.DELETE_ACCOUNT },
                                    "exact-active-class":
                                      "font-weight-bold unbox_primary--text",
                                    height: "auto",
                                    text: "",
                                    plain: "",
                                    ripple: false,
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-body-2 px-0" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("label.deleteAccount"))
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _c("v-col", { attrs: { cols: "12", md: "8" } }, [
                  _c("div", [_c("router-view")], 1),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }